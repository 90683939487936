window.addEventListener("load", () => {
  let isNavScrolling = false;

  navScroll();

  function navScroll() {
    const nav = document.querySelector(".banner");
    const threshold = 10;
    if (window.scrollY > threshold && !isNavScrolling) {
      nav.classList.add("scrolling");
      isNavScrolling = true;
    } else if (window.scrollY <= threshold && isNavScrolling) {
      nav.classList.remove("scrolling");
      isNavScrolling = false;
    }
  }

  window.addEventListener("scroll", navScroll);
});
