import Swiper from "swiper/bundle";
import EffectMaterial from "./effect-material.esm.js";

function initSwiper() {
  new Swiper(".swiper-1 .swiper", {
    modules: [EffectMaterial],
    effect: "material",
    loop: true,
    materialEffect: {
      slideSplitRatio: 0.65,
    },
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 600,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
      },
    },
  });

  new Swiper(".swiper-2 .swiper", {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  new Swiper(".swiper-3 .swiper", {
    grabCursor: true,
    initialSlide: "2",
    slidesPerView: "1.2",
    centeredSlides: true,
    spaceBetween: 24,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      768: {
        spaceBetween: 32,
        slidesPerView: 3,
        centeredSlides: false,
      },
      992: {
        spaceBetween: 32,
        slidesPerView: 4,
        centeredSlides: false,
      },
    },
  });

  new Swiper(".service-slider .swiper", {
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

initSwiper();
